/*Add Customer App Specific CSS or SCSS Here*/
.inline-block {
  display: inline-block;
}

.mr-md {
  margin-right: 7px;
}

img.rounded {
  border-radius: 9999px;
}

.is-absolute {
  position: absolute !important;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.pad-top-4 {
  padding-top: 4rem;
}

.sidebar-logo {
  min-height: 4rem;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.mr-15 {
  margin-right: 15px;
}

.vertical-middle {
  vertical-align: middle !important;
}

.pointer {
  cursor: pointer;
}

.table th {
  position: sticky;
  top: 0;
  background: white;
  vertical-align: middle;
  z-index: 10;
  white-space: nowrap;
  /*box-shadow: 0 8px 6px -6px black;*/
}

.table td {
  white-space: nowrap;
}

.table th.actions {
  position: sticky;
  right: 0;
  z-index: 20;
}

.table td.actions {
  position: sticky;
  right: 0;
  z-index: 10;
}

.th-icon {
  display: inline-block;
  margin-left: 5px;
  opacity: 0;
}

.th:hover .th-icon {
  opacity: 1;
}

.th-icon.selected {
  opacity: 1;
}

.table-container {
  overflow: scroll;
  max-height: 75vh;
}

.table-search {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.absolute-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.max-height-4rem {
  max-height: 4rem;
}

.min-h-100 {
  min-height: 100vh !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.table tbody tr {
  cursor: pointer;
}

.not-visible {
  visibility: hidden;
}

.no-wrap {
  white-space: nowrap;
}

.min-h-30 {
  min-height: 30vh !important;
}

.bg-corn {
  background-image: url("/img/large-corn-r.png");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}

.bg-soy {
  background-image: url("/img/soy.jpg");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}

.bg-corn-right {
  background-image: url("/img/large-corn-r.png");
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
}

.bg-soybeans-right {
  background-image: url("/img/soy.jpg");
  background-size: contain;
  background-position: bottom right 15px;
  background-repeat: no-repeat;
}

.min-h-200 {
  min-height: 200px !important;
}

.min-h-300 {
  min-height: 300px !important;
}

.justify-center {
  justify-content: center !important;
}

.bring-together {
  justify-content: center !important;
}

.bring-together .level-item {
  flex-grow: inherit !important;
}

.capitalize {
  text-transform: capitalize;
}

.crop-table-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
}

.crop-table-img.corn {
  transform: rotate(180deg);
}

/*.search-input input::placeholder {
  color: $black-bis;
}*/

.breadcrumb li + li::before {
  color: #000;
  content: "\0002f";
}

.m-0 {
  margin: 0 !important;
}

.max-h-25 {
  max-height: 25px;
}

.remington-header-bg {
  background-color: #514a40 !important;
  color: #fff !important;

  .navbar-item, .navbar-link {
    color: #fff !important;
  }

  .navbar-item.has-dropdown:hover, .navbar-link:hover, .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #bd723c !important;
  }

  .navbar-dropdown {
    background-color: #bd723c;
  }

  .navbar-dropdown a.navbar-item.is-active, .navbar-dropdown a.navbar-item:hover {
    background-color: #bd723c;
  }
}

.fa-bars, .fa-times {
  cursor: pointer;
}

.max-width-100vw {
  max-width: 100vw !important;
}

.max-height-100vh {
  max-height: 100vh !important;
}

.max-w-100{
  max-width: 100% !important;
}

.z-index-25 {
  z-index: 25 !important;
}
